import React from "react"

export class Sales extends React.Component {
    constructor(props) {
        super(props)
        this.$ = props.parent
        this.fn = props.fn
        this.state = { ...props }
    }
    componentDidMount() {}
    onRefresh = () => {}
    render($) {
        $ = this.$
        return (
            <div className="row">
                {$.info.access.includes("sales_by_customer") ? <div className="col-md-4"></div> : <></>}
                {$.info.access.includes("sales_by_item") ? <div className="col-md-4"></div> : <></>}
                {$.info.access.includes("sales_by_salesman") ? <div className="col-md-4"></div> : <></>}
            </div>
        )
    }
}
