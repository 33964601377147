import { alert } from "devextreme/ui/dialog"
import { PostData } from "../../../Helper"
import { onClearSelection, onContentReady, onContentReadyDetail, onContentReadyPopup, onRefresh, onToolbarPreparingTreeUser } from "./event"

export const settingColumn = ($, e, info, x, i, a) => {
    if (e === undefined || e === null) return undefined
    a = {}
    x = {
        lookup: e.filter((expr) => expr.data !== undefined),
        onChange: e.filter((expr) => expr.onChange !== undefined),
        i: 0,
        decimal: e.filter((expr) => expr.dataType === "decimal"),
        percentage: e.filter((expr) => expr.dataType === "percentage"),
        date: e.filter((expr) => expr.dataType === "date"),
        datetime: e.filter((expr) => expr.dataType === "datetime"),
        image: e.filter((expr) => expr.dataType === "image"),
        itemUnit: 0,
        button: e.filter((expr) => (expr.type !== "buttons" ? false : Array.isArray(expr.buttons)))
    }
    if (x.button.length <= 0) {
        a.button = []
        if ($.access.includes("edit")) a.button.push("edit")
        if ($.access.includes("delete")) a.button.push("delete")
        if ($.access.includes("print")) a.button.push("print")
        if ($.access.includes("user")) a.button.push("user")
        e.push({ type: "buttons", buttons: a.button })
        x.button = e.filter((expr) => (expr.type !== "buttons" ? false : Array.isArray(expr.buttons)))
    }
    if (info !== undefined) {
        if (info.extra === undefined || info.extra === null) info.extra = []
        if (info.filter !== undefined)
            for (i in info.filter)
                if (typeof info.filter[i] === "object")
                    if (info.filter[i].table !== undefined) if (!info.extra.includes(info.filter[i].table)) info.extra.push(info.filter[i].table)
    }
    for (x.i in x.lookup) {
        if (typeof x.lookup[x.i].data === "string") x.lookup[x.i].data = { table: x.lookup[x.i].data }
        x.table = x.lookup[x.i].data
        x.tableName = typeof x.table === "string" ? x.table : x.table.table
        if (info !== undefined) if (x.tableName !== $.flag && !info.extra.includes(x.tableName)) info.extra.push(x.tableName)
        x.lookup[x.i].lookup = settingLookup($, x.table)
        // if (["item", "unit"].includes(x.tableName)) x.itemUnit += 1
    }
    // if (x.itemUnit >= 2) info.extra.push("item_unit")
    if (info !== undefined) for (x.i in x.onChange) x.onChange[x.i].setCellValue = settingSetCellValue($, x.onChange[x.i], info)
    for (x.i in x.decimal) x.decimal[x.i] = settingDecimal($, x.decimal[x.i])
    for (x.i in x.date) x.date[x.i] = settingDate($, x.date[x.i])
    for (x.i in x.datetime) x.datetime[x.i] = settingDateTime($, x.datetime[x.i])
    for (x.i in x.percentage) x.percentage[x.i] = settingPercentage($, x.percentage[x.i])
    for (x.i in x.button) x.button[x.i] = settingButton($, x.button[x.i])
    for (x.i in x.image) x.image[x.i] = settingImage($, x.image[x.i])
    return e
}
export const settingButton = ($, e, i) => {
    for (i in e.buttons)
        switch (e.buttons[i]) {
            case "print":
                e.buttons[i] = {
                    icon: "print",
                    onClick: (expr, x, j, config) => {
                        config = $.fn.config($.flag)
                        if (config !== undefined)
                            if (config.preventPrint !== undefined)
                                for (j in config.preventPrint)
                                    if (config.preventPrint[j].includes(expr.row.data[j])) return window.toastr.warning($.__.noDataPrinted)
                        // PostData({
                        //     url: $.fn.url("print"),
                        //     data: { id: expr.row.data.id },
                        //     success: (res, link, blob) => {
                        //         blob = new Blob([res.message])
                        //         console.log(blob)
                        //         link = document.createElement("a")
                        //         link.setAttribute("href", window.URL.createObjectURL(blob))
                        //         link.setAttribute("download", expr.row.data.id + ".pdf")
                        //         link.click()
                        //     }
                        // })
                        x = { token: localStorage.getItem(window.token) }
                        if (x.token === undefined || x.token === null) x.token = ""
                        if (x.token !== "") if (x.token.substring(0, 7).toLowerCase() === "bearer ") x.token = x.token.substr(6)
                        window
                            .$("body")
                            .append((x.form = window.$('<form action="' + window.url + $.fn.url("print") + '" target="_blank" method="POST">')))
                        x.form.append(window.$('<input type="hidden" name="_token" value="' + x.token + '">'))
                        x.form.append(window.$('<input type="hidden" name="id" value="' + expr.row.data.id + '">'))
                        x.form.append(window.$('<input type="hidden" name="_target" value="' + (process.env.REACT_APP_TARGET ?? "") + '">'))
                        x.form.append(window.$('<input type="hidden" name="_unique" value="' + localStorage.getItem(window.tokenUnique) + '">'))
                        x.form.append(window.$('<input type="hidden" name="_key" value="' + window.token + '">'))
                        x.form.append(window.$('<input type="hidden" name="_dir" value="' + process.env.REACT_APP_DIR + '">'))
                        x.form.submit()
                        setTimeout(() => x.form.remove())
                    }
                }
                break
            case "user":
                e.buttons[i] = {
                    icon: "user",
                    onClick: (expr, x) => {
                        x = { data: expr.row.data, access: $.fn.data("access") }
                        PostData({
                            url: $.fn.url("access"),
                            showLoading: true,
                            data: { id: x.data.id, method: "get" },
                            success: (result, j) => {
                                x.data.email = result.email
                                $.popup.user.beginUpdate()
                                $.popup.user.option("title", $.info.name + " - " + x.data.name)
                                $.popup.user.option("visible", true)
                                $.popup.user.endUpdate()
                                // what ??
                                $.tree.user.option("dataSource", [])
                                setTimeout((tmp) => {
                                    $.tree.user.beginUpdate()
                                    $.tree.user.option("dataSource", x.access)
                                    $.tree.user.option("onToolbarPreparing", (expr) => onToolbarPreparingTreeUser($, expr, x.data))
                                    $.tree.user.clearSelection()
                                    $.tree.user.option("selectedRowKeys", result.access)
                                    $.tree.user.option("autoExpandAll", true)
                                    $.tree.user.endUpdate()
                                })
                            }
                        })
                    }
                }
                break
        }
}
export const settingDecimal = ($, e, x, i) => {
    if (e.decimal === undefined || e.decimal === null) e.decimal = 2
    x = { commas: "" }
    for (i = 0; i < e.decimal; i++) x.commas += "#"
    if (x.commas !== undefined) x.commas = "." + x.commas

    e.dataType = "number"
    e.format = "#,##0" + x.commas + ";-#,##0" + x.commas + ""
    e.editorOptions = { format: "#,##0" + x.commas + ";-#,##0" + x.commas + "" }
    if (e.onChange !== undefined) {
        if (typeof e.onChange === "string") e.onChange = [e.onChange]
        if (e.onChange.includes("abs")) {
            e.format = "#,##0" + x.commas + ";#,##0" + x.commas + ""
            e.editorOptions = { format: "#,##0" + x.commas + ";#,##0" + x.commas + "" }
        }
    }
    return e
}
export const settingPercentage = ($, e, x, i) => {
    if (e.decimal === undefined || e.decimal === null) e.decimal = 2
    x = { commas: "" }
    for (i = 0; i < e.decimal; i++) x.commas += "#"
    if (x.commas !== undefined) x.commas = "." + x.commas

    e.dataType = "number"
    e.format = "#,##0" + x.commas + " ‰;-#,##0" + x.commas + " ‰"
    e.editorOptions = { format: "#,##0" + x.commas + " ‰;-#,##0" + x.commas + " ‰" }
    return e
}
export const settingDate = ($, e) => {
    e.format = "dd MMM yyyy"
    return e
}
export const settingDateTime = ($, e) => {
    e.format = "dd MMM yyyy HH:mm:ss"
    return e
}
export const settingImage = ($, e) => {
    e.cellTemplate = (container, options, x) => {
        window.$(container).css({ height: "50px" })
        x = { value: options.value }
        try {
            x.value = JSON.parse(x.value)
        } catch (ex) {}
        if (typeof x.value === "string") x.value = [x.value]
        if (x.value.length > 0) {
            setTimeout((i, img) => {
                for (i in x.value) if (x.value[i].substr(0, 4) !== "http") x.value[i] = "img/" + x.value[i]

                img = window
                    .$('<img height="50px">') // src="' + x.value[0] + '"
                    .appendTo(container)
                    .on("click", (x, i, j) => {
                        x = { popUp: window.popUp(), title: "", temp: [] }
                        for (i in e.info) {
                            for (j in e.info[i]) {
                                if (x.temp[i] === undefined) x.temp[i] = ""
                                else if (x.temp[i] !== "") x.temp[i] += " - "
                                x.column = e.info[i][j].column
                                x.value = options.data[x.column]
                                if ((x.tbl = e.info[i][j].data) !== undefined)
                                    if ((x.row = $.fn.data(x.tbl).filter((expr) => expr.id === x.value)).length > 0) x.temp[i] += x.row[0].name
                                    else x.temp[i] += x.value
                                else x.temp[i] += x.value
                            }
                            x.title += x.temp[i] + "<br />"
                        }
                        x.popUp.child.addClass("text-center")
                        x.popUp.child.append(window.$("<div>").addClass("p-2 pb-0 d-inline-block w-100 text-center").append(x.title))
                        x.popUp.child.append(window.$('<img src="' + img.attr("src") + '" style="max-height: 100%;">').addClass("pb-3"))
                        x.popUp.object.on("click", () => x.popUp.hide())
                    })
                    .css("display", "none")
                // if ($.imageCollection[x.value[0]] === undefined){

                // }
                // $.imageCollection[x.value[0]] = ""
                PostData({
                    url: x.value[0],
                    responseType: "blob",
                    success: (res) => {
                        img.attr("src", res).fadeIn()
                    }
                })
            }, 250 * options.rowIndex)
        }
    }
    return e
}
export const settingLookup = ($, e, x) => {
    if (typeof e === "string") e = { table: e }
    if (e.displayExpr === undefined || e.displayExpr === null) e.displayExpr = "name"
    if (e.valueExpr === undefined || e.valueExpr === null) e.valueExpr = "id"
    if (e.allowClearing === undefined || e.allowClearing === null) e.allowClearing = true

    x = {
        ds: (options, x, i) => {
            x = { filter: e.filter, data: [...$.fn.data(e.table)] }
            if (e.filter !== undefined)
                if (typeof e.filter === "string")
                    if (options.data === undefined) x.filter = ["is_active", "=", 1]
                    else x.filter = [e.filter, "=", options.data[e.filter]]
            x.filter =
                e.filter !== undefined
                    ? x.filter
                    : $.flag === e.table
                    ? options.data
                        ? ["id", "<>", options.data.id + "" ?? ""]
                        : ["id", "<>", ""]
                    : ["is_active", "=", 1]
            return {
                store: x.data,
                loadMode: "raw",
                paginate: true,
                pageSize: 10,
                filter: x.filter
            }
        }
    }
    // setting sini
    e.output = {
        dataSource: x.ds,
        displayExpr: e.displayExpr,
        valueExpr: e.valueExpr,
        allowClearing: e.allowClearing
    }
    return e.output
}
export const settingSetCellValue = ($, e, info, i, d) => {
    e.tableName = ""
    if (e.data !== undefined && e.data !== null) e.tableName = e.data.table
    return (current, value, old, data, x, i, j, k, l, ii) => {
        current[e.dataField] = value
        if (e.tableName !== "") {
            data = $.fn.data(e.tableName).filter((expr) => expr[e.data.valueExpr] === value)
            if (data.length > 0) data = data[0]
            else data = null
        } else {
            data = null
        }
        if ($.old === undefined || $.old === null) $.old = $.$.old
        if (typeof e.onChange === "string") e.onChange = [e.onChange]
        for (i in e.onChange) {
            x = {}
            if (typeof e.onChange[i] === "object")
                for (ii in e.onChange[i])
                    switch (ii) {
                        case "detail_formula_material":
                            x.target = e.onChange[i][ii]
                            x.item_id = current.item_id ?? old.item_id
                            x.formula_id = current.formula_id ?? old.formula_id ?? ""
                            x.date = current.date ?? old.date
                            x.warehouse_id = current.warehouse_id ?? old.warehouse_id
                            // $.grid.cellValue($.old.index, "date")
                            // console.log({ item_id: x.item_id, target: x.target, date: x.date })
                            $.details[x.target].grid.option("dataSource", [])
                            if (x.formula_id === "") {
                                window.toastr.warning($.__.pleaseSelectFormula)
                                break
                            }
                            PostData({
                                url: $.fn.url("material"),
                                data: { item_id: x.item_id, target: x.target, date: x.date, warehouse_id: x.warehouse_id, formula_id: x.formula_id },
                                showLoading: true,
                                success: (result) => {
                                    $.details[result.target].grid.option("dataSource", ($.details[result.target].dataSource = result.data))
                                    $.grid.beginUpdate()
                                    // $.grid.cellValue($.old.index, "qty_base", result.qty_base)
                                    // $.grid.cellValue($.old.index, "multiplier", result.multiplier)
                                    $.grid.cellValue($.old.index, "qty_ori", result.qty_ori)
                                    $.grid.cellValue($.old.index, "qty_ori_base", result.qty_ori_base)
                                    $.grid.cellValue($.old.index, "qty_actual", result.qty)
                                    $.grid.cellValue($.old.index, "qty", result.qty)
                                    $.grid.cellValue($.old.index, "subtotal", result.subtotal)
                                    $.grid.cellValue($.old.index, "unit_id", result.unit_id)
                                    $.grid.endUpdate()
                                }
                            })
                            break
                        case "material_qty":
                            x.target = e.onChange[i][ii]
                            x.subtotal = 0
                            x.qty = current.qty ?? old.qty ?? 0
                            x.qty_base = current.qty_base ?? old.qty_base ?? 0
                            x.qty_ori_base = current.qty_ori_base ?? old.qty_ori_base
                            if (x.qty_ori_base === null || x.qty_ori_base === undefined) break
                            x.div = x.qty_base / x.qty_ori_base
                            for (j in $.details[x.target].dataSource) {
                                x.row = $.details[x.target].dataSource[j]
                                // x.row.qty_base = Math.round(x.div * x.row.qty_ori_base * 100) / 100
                                x.row.qty = Math.round(x.div * x.row.qty_ori * 100) / 100
                                x.row.qty_base = x.row.qty * x.row.multiplier
                                x.row.subtotal = x.row.price * x.row.qty
                                x.subtotal += x.row.subtotal
                            }
                            current.subtotal = +x.subtotal
                            current.total = current.subtotal
                            x.cost = +(current.cost ?? old.cost ?? 0)
                            current.total_detail = current.subtotal + x.cost
                            $.details[x.target].grid.option("dataSource", $.details[x.target].dataSource)
                            break
                    }
            else
                switch (e.onChange[i]) {
                    case "subtotal_to_price":
                        x.qty = current.qty
                        if (x.qty === undefined) x.qty = old.qty
                        x.qty_actual = current.qty_actual ?? old.qty_actual
                        if (x.qty_actual === undefined || x.qty_actual === null) current.qty_actual = x.qty_actual = x.qty
                        current.qty_decreased = x.qty - x.qty_actual
                        if (x.qty_actual !== 0) current.price = (current.subtotal ?? old.subtotal ?? 0) / x.qty_actual
                        break
                    case "clearPull":
                        if (old[e.dataField] === value) continue
                        for (j in $.details) {
                            d = $.details[j]
                            x.temp = d.onToolbar
                            if (x.temp !== undefined) x.temp = x.temp.pull
                            if (x.temp === undefined) continue
                            x.data = d.grid.option("dataSource")
                            if (x.data === undefined) continue
                            if (x.data.length <= 0) continue
                            for (k in x.temp) for (l in x.temp[k].check) x.data = x.data.filter((expr) => expr[e.dataField] !== old[e.dataField])
                            d.grid.option("dataSource", (d.dataSource = x.data))
                            if (typeof d.onSaved !== "undefined") eval(d.onSaved)
                            // x.data = $.details[j].grid.getDataSource()._items
                        }
                        break
                    case "base":
                        x.unit_id = current.unit_id ?? old.unit_id
                        x.qty = current.qty ?? old.qty
                        x.item_id = current.item_id ?? old.item_id
                        x.item = ($.fn.data("item") ?? []).filter((expr) => expr.id === x.item_id)
                        current.multiplier = 1
                        if (x.item.length > 0) {
                            x.item = x.item[0]
                            if (x.item.base_unit_id !== x.unit_id) {
                                x.item_unit = ($.fn.data("item_unit") ?? []).filter(
                                    (expr) => expr.item_id === x.item_id && expr.to_unit_id === x.unit_id
                                )
                                if (x.item_unit.length > 0) current.multiplier = x.item_unit[0].multiplier
                            }
                        }
                        current.qty_base = x.qty * current.multiplier
                        current.qty_left = current.qty_base
                        break
                    case "price_base":
                        x.price_base = current.price_base ?? old.price_base
                        x.multiplier = current.multiplier ?? old.multiplier
                        x.price = x.price_base * x.multiplier
                        current.price = x.price
                        break
                    case "abs":
                        value = Math.abs(value)
                        current[e.dataField] = value
                        break
                    case "percentage":
                        value = current[e.dataField] = value
                        break
                    case "qty":
                        x.multiplier = +(current.multiplier ?? old.multiplier ?? 0)
                        if (x.multiplier === 0) {
                            current.multiplier = 1
                            x.multiplier = 1
                        }
                        x.qty = current.qty ?? old.qty
                        x.qty_base = x.qty * x.multiplier

                        x.qty_limit = current.qty_limit ?? old.qty_limit
                        if (!isNaN(x.qty_limit))
                            if (x.qty_base > x.qty_limit) {
                                x.qty = x.qty_limit / x.multiplier
                                old.qty = 0
                                old.qty_base = 0
                                current.qty = x.qty
                                current.qty_base = x.qty_limit
                                window.toastr.warning($.__.invalid.qty_limit)
                            }
                        break
                    case "total":
                        x.multiplier = +(current.multiplier ?? old.multiplier ?? 0)
                        if (x.multiplier === 0) {
                            current.multiplier = 1
                            x.multiplier = 1
                        }
                        x.oldTotal = current.total
                        if (x.oldTotal === undefined) x.oldTotal = old.total
                        if (isNaN(x.oldTotal)) x.oldTotal = 0

                        x.qty = current.qty
                        if (x.qty === undefined) x.qty = old.qty
                        x.qty_actual = current.qty_actual ?? old.qty_actual
                        if (x.qty_actual === undefined || x.qty_actual === null) current.qty_actual = x.qty_actual = x.qty
                        current.qty_decreased = x.qty - x.qty_actual

                        x.price = current.price
                        if (x.price === undefined) x.price = old.price
                        if (isNaN(x.price)) x.price = 0
                        x.subtotal = x.qty_actual * x.price
                        if (isNaN(x.subtotal)) x.subtotal = 0
                        current.subtotal = x.subtotal

                        x.discount_percentage = current.discount_percentage
                        if (x.discount_percentage === undefined) x.discount_percentage = old.discount_percentage ?? "0"
                        x.discount = current.discount
                        if (x.discount === undefined) x.discount = old.discount ?? 0

                        x.total = x.price
                        x.discountPercentage = (x.discount_percentage + "").split("+")
                        x.percentage = ""
                        for (i in x.discountPercentage) {
                            x.temp = (x.discountPercentage[i] + "").trim()
                            x.minus = x.temp.split("-")
                            x.percentageMinus = ""
                            if (x.minus.length > 1) {
                                for (j in x.minus) {
                                    x.temp = (x.minus[j] + "")
                                        .trim()
                                        .replace(/[^0-9.-]/g, "")
                                        .replace(/^(-)|-+/g, "$1")
                                        .replace(/^([^.]*\.)|\.+/g, "$1")
                                    if (!isNaN(x.temp) && x.temp !== "") {
                                        if (j > 0) x.percentageMinus += "-"
                                        x.percentageMinus += x.temp
                                        if (j > 0) x.total *= (100 + +x.temp) / 100
                                        else x.total *= (100 - +x.temp) / 100
                                    }
                                }
                                if (x.percentageMinus !== "") {
                                    if (x.percentage !== "") x.percentage += "+"
                                    x.percentage += x.percentageMinus
                                }
                            } else {
                                x.temp = x.temp
                                    .replace(/[^0-9.-]/g, "")
                                    .replace(/^(-)|-+/g, "$1")
                                    .replace(/^([^.]*\.)|\.+/g, "$1")
                                if (!isNaN(x.temp) && x.temp !== "") {
                                    if (x.percentage !== "") x.percentage += "+"
                                    x.percentage += x.temp
                                    x.total *= (100 - +x.temp) / 100
                                }
                            }
                        }
                        current.discount_percentage = x.percentage
                        x.discount_percentage = x.percentage
                        if (isNaN(x.total)) x.total = 0
                        x.total -= x.discount
                        if (isNaN(x.total)) x.total = 0
                        x.total *= x.qty_actual
                        if (isNaN(x.total)) x.total = 0
                        current.total = x.total

                        current.qty_base = x.qty * x.multiplier
                        current.qty_left = current.qty_base
                        break
                    case "date":
                    case "date_with_is_cash_or_bank":
                    case "date_with_coa_key":
                        x.with = e.onChange[i].split("_with_")
                        x.parameter = { extra: "" }
                        if (x.with.length > 1) x.parameter.extra = current[x.with[1]] ?? old[x.with[1]] ?? ""
                        $.extra = x.parameter.extra

                        if (old.date !== undefined) if (typeof old.date.getMonth !== "function") old.date = new Date(old.date)
                        x.date = current.date ?? old.date ?? new Date("0000-01-01")
                        if (typeof x.date.getMonth !== "function") x.date = new Date(x.date)
                        x.parameter.date =
                            x.date.getFullYear() + "-" + ("0" + (x.date.getMonth() + 1)).slice(-2) + "-" + ("0" + x.date.getDate()).slice(-2)

                        x.cekdate = old.date ?? new Date("0000-01-01")
                        if ($.old.key !== null) current.date = old.date
                        else if (
                            x.date.getMonth() !== x.cekdate.getMonth() ||
                            x.date.getFullYear() !== x.cekdate.getFullYear() ||
                            e.dataField !== "date"
                        )
                            PostData({
                                url: $.fn.url("id"),
                                data: x.parameter,
                                success: (res) => {
                                    $.grid.beginUpdate()
                                    $.grid.cellValue(0, "id", res.id)
                                    if ([undefined, null].includes($.grid.cellValue(0, "subtotal"))) $.grid.cellValue(0, "subtotal", 0)
                                    if ([undefined, null].includes($.grid.cellValue(0, "total"))) $.grid.cellValue(0, "total", 0)
                                    $.grid.endUpdate()
                                }
                            })
                        break
                    case "payment":
                        x.total = current.total //total
                        if (x.total === undefined) x.total = old.total
                        x.paid = current.paid //paid
                        if (x.paid === undefined) x.paid = old.paid
                        x.nominal = current.nominal //nominal
                        if (x.nominal === undefined) x.nominal = old.nominal
                        x.discount = current.discount //discount
                        if (x.discount === undefined) x.discount = old.discount

                        current.payment_left = x.total - x.paid - x.nominal - x.discount

                        break
                    default:
                        try {
                            eval(e.onChange[i])
                        } catch (ex) {
                            window.toastr.error(ex.message)
                        }
                        break
                }
        }
    }
}
export const settingPopup = ($, container) => {
    if (container === undefined) container = "#pg-" + $.flag
    return {
        showTitle: true,
        dragEnabled: false,
        container: container,
        position: { my: "center", at: "center", of: container },
        width: "90%",
        height: "90%",
        wrapperAttr: { id: "pop-" + $.flag },
        onContentReady: (e) => onContentReadyPopup($),
        onHidden: (e) => onContentReady($),
        toolbarItems: [
            {
                widget: "dxButton",
                location: "before",
                options: {
                    icon: "refresh",
                    text: $.info.name,
                    onClick: () => onRefresh($)
                }
            },
            {
                widget: "dxButton",
                location: "before",
                options: {
                    icon: "save",
                    text: $.__.save,
                    onClick: () => $.grid.saveEditData(),
                    elementAttr: { class: "btn-save" }
                }
            }
        ]
    }
}
export const settingSelection = ($, x) => {
    // if (x !== undefined && x !== null) $.selection = x
    if ($.grid === undefined || $.grid === null) return
    if ($.state.config === undefined || $.state.config === null) return
    if ($.state.config.selection === undefined || $.state.config.selection === null) return
    $.grid.option("selection", $.state.config.selection)
}
export const settingEditing = ($, x, form, i) => {
    if (x !== undefined && x !== null) $.editing = x
    if (form !== undefined && form !== null) $.form = form
    if ($.grid === undefined || $.grid === null) return
    x = {
        editing: { ...$.grid.option("editing") },
        pg: "#pg-" + $.flag,
        config: $.fn.config($.flag)
    }
    x.form = x.config.forms
    x.editing.mode = $.editing
    if ($.editing === "popup") x.editing.popup = settingPopup($, x.pg)
    if (x.form !== undefined && x.form !== null) x.editing.form = x.form

    $.grid.beginUpdate()
    $.grid.option("editing", x.editing)
    if (x.config.summary !== undefined) {
        for (i in x.config.summary.totalItems) x.config.summary.totalItems[i].customizeText = settingSummaryText(x.config.summary.totalItems[i])
        $.grid.option("summary", x.config.summary)
    }
    $.grid.endUpdate()
}
export const settingSummaryText = (o) => {
    if (o.text !== undefined && o.text !== null) return (e) => o.text
    else return (e) => (+e.value).toLocaleString("en-US")
}
export const settingDetail = ($, e, grid, x, i, id) => {
    if ($.details === undefined || $.details === null) $.details = {}
    id = ""
    if ($.details[e.name] === undefined || $.details[e.name] === null) $.details[e.name] = e
    else id = $.details[e.name].id

    $.details[e.name].grid = grid
    if (e.number === undefined || e.number === null) e.number = "number"
    if (e.id === undefined) {
        //save
        x = { columns: $.fn.detail($.flag + "_" + e.name), data: grid.getDataSource()._items }
        x.number = x.columns.filter((expr) => expr.dataField === e.number)
        if (x.number.length > 0) {
            if (x.data.length > 1) x.data[x.data.length - 1][e.number] = +x.data[x.data.length - 2][e.number] + 1
            else if (x.data.length > 0) x.data[0][e.number] = 0
        }
        grid.option("dataSource", ($.details[e.name].dataSource = x.data))
        if ($.forParent !== null) {
            $.grid.beginUpdate()
            for (i in $.forParent) $.grid.cellValue($.old.index, i, $.forParent[i])
            $.grid.endUpdate()
        }
        $.forParent = null
    } else if (id !== e.id || id === "") {
        if (id === "") id = null
        //edit / new
        $.details[e.name].id = e.id
        if ((e.id ?? "") !== "")
            PostData({
                url: $.fn.url("detail"),
                data: { id: e.id, name: e.name },
                showLoading: true,
                success: (res, p, x) => {
                    p = $.details[e.name]
                    x = {}
                    grid.option("dataSource", (p.dataSource = res.data))
                    if (typeof p.onSaved !== "undefined") eval(p.onSaved)
                },
                warning: (res) =>
                    alert("<div class=''>" + res.message + "<hr />" + e.id + "</div>", null).then(() => {
                        $.details[e.name].id = null
                        $.details[e.name].dataSource = []
                        $.grid.cancelEditData()
                    }),
                error: (res) =>
                    alert("<div class='text-danger'>" + res.message + "<hr />" + e.id + "</div>", null).then(() => {
                        $.details[e.name].id = null
                        $.details[e.name].dataSource = []
                        $.grid.cancelEditData()
                    })
            })
        else grid.option("dataSource", ($.details[e.name].dataSource = []))
    } else {
        //ga ngapa2in
        setTimeout(() => grid.option("dataSource", $.details[e.name].dataSource))
    }
    return $.details[e.name]
}
