import React from "react"
import { Debt } from "../dashboard/debt"
import { Sales } from "../dashboard/sales"
import { PostData } from "../../Helper"

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.parent = props.state
        this.fn = props.fn
        this.state = { ...props }

        try {
            this.parent._ = JSON.parse(localStorage.getItem(window.tokenInfo))
            this.parent.__ = this.parent._.msg
        } catch (ex) {
            this.parent.__ = {}
        }
    }
    componentDidMount() {
        if (!this.state.ready) this.onRefresh(this.parent)
    }
    onRefresh = ($) => {
        PostData({
            showLoading: true,
            url: this.fn.url("index"),
            success: (e) => this.setState(e),
            error: (e) => this.setState({ message: e.message, data: undefined }),
            warning: (e) => this.setState({ message: e.message, data: undefined })
        })
    }
    render($) {
        $ = this.parent
        if ($.info.access === undefined || $.info.access === null) $.info.access = []
        return this.state.data !== undefined && this.state.data !== null ? (
            <>
                <button className="btn btn-light btn-sm my-1" onClick={() => this.onRefresh($)}>
                    <span className="fa fa-refresh" /> Refresh
                </button>
                {$.info.access.includes("customer_debt") || $.info.access.includes("supplier_debt") ? (
                    <Debt fn={this.fn} parent={this.parent} data={this.state.data} title={this.state.title} />
                ) : (
                    <></>
                )}
                {$.info.access.includes("sales_by_customer") ||
                $.info.access.includes("sales_by_item") ||
                $.info.access.includes("sales_by_salesman") ? (
                    <Sales fn={this.fn} parent={this.parent} data={this.state.data} title={this.state.title} />
                ) : (
                    <></>
                )}
            </>
        ) : this.state.message ? (
            <>
                <div className="alt-font-bold">{this.state.message}</div>
                <button className="btn btn-light btn-sm mt-1" onClick={() => this.onRefresh($)}>
                    <span className="fa fa-exchange" /> Retry
                </button>
            </>
        ) : (
            <div className="alt-font-bold">
                <span className="fa fa-circle-o-notch fa-spin"></span> Please wait...
            </div>
        )
    }
}
export default Dashboard
